<template>
  <v-row class="item-bio-panel">
    <!-- item profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-img class="mb-3" :src="itemData.image ? itemData.image : ''"></v-img>
          <span class="mb-2">{{ itemData.name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Promotion id:</span>
              <span class="text--secondary">#{{ zeroP(itemData.id) }}</span>
            </v-list-item>
            
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Linked with:</span>
              <span v-if="itemData.type_id != null" class="text--secondary">{{
                itemData.type_id == 0
                  ? 'Nothing'
                  : itemData.type_id == 1
                  ? 'Chief'
                  : itemData.type_id == 2
                  ? 'Category'
                  : itemData.type_id == 3
                  ? 'Meal'
                  : itemData.type_id == 4
                  ? 'Event'
                  : ''
              }}</span>
              <span v-if="itemData.link" class="text--secondary">
                <a class="link" target="_blank" :href="itemData.link">{{ itemData.link }}</a>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-if="itemData.type_id == 3">
              <span class="font-weight-medium me-2">Item ID:</span>
              <span class="text--secondary">{{ itemData.item_id }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Priority:</span>
              <span class="text--secondary">{{ itemData.priority }}</span>
            </v-list-item>

            <v-card-actions class="justify-center mt-3">
              <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen"> Edit </v-btn>
              <v-btn color="error" outlined @click="removeItem(itemData.id)"> Delete </v-btn>
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <promotion-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :item-data="itemData"
        @refresh="updateValue($event)"
      ></promotion-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import PromotionBioEdit from './PromotionBioEdit.vue'

export default {
  components: {
    PromotionBioEdit,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('refresh', value)
    },
    removeItem(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then(result => {
          if (result.isConfirmed) {
            store
              .dispatch('app-promotion/deletePromotion', id)
              .then(() => {
                Swal.fire('Deleted!', 'entry has been deleted.', 'success')
                this.$router.push({ path: '/apps/promotion/list' })
              })
              .catch(error => {
                let textValue = null
                if (_.isObject(error.data.message)) {
                  textValue = `${Object.values(error.data.message).join('\n')}`
                } else {
                  textValue = error.data.message
                }
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: textValue,
                })
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  setup() {
    const isBioDialogOpen = ref(false)
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const zeroP = (val) => {
      return zeroPad(val,4)
    }
    return {
      zeroP,
      avatarText,
      kFormatter,

      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
<style scoped>
.link{
  text-decoration: none;
  font-weight: bold;
  color: #a36868;
}
</style>