<template>
  <div id="item-view">
    <v-row>
      <v-col
        cols="12"
      >
        <promotion-bio-panel
          :item-data="itemData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="updateItemValue"
        ></promotion-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mdiAccountOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import promotionStoreModule from '../PromotionStoreModule'
import PromotionBioPanel from './promotion-bio-panel/PromotionBioPanel.vue'

export default {
  components: {
    PromotionBioPanel,
  },
  setup() {
    const ITEMS_APP_STORE_MODULE_NAME = 'app-promotion'

    // Register module
    if (!store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ITEMS_APP_STORE_MODULE_NAME, promotionStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEMS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref({})
    const isPlanUpgradeDialogVisible = ref(false)

    const updateItemValue = () => {
      store
        .dispatch('app-promotion/fetchPromotion', { id: router.currentRoute.params.id })
        .then(response => {
          itemData.value = response.data.data
          console.log('itemData', itemData.value)
        })
        .catch(error => {
          if (error.response.status === 404) {
            itemData.value = {}
          }
        })
    }

    updateItemValue()


    return {
      updateItemValue,
      isPlanUpgradeDialogVisible,
      itemData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
