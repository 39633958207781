<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="800px"
    @click:outside="$emit('update:is-bio-dialog-open', false), cancel()"
  >
    <v-card class="item-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Promotion #{{ zeroP(itemData.id) }} </v-card-title>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-row class="mb-6" v-if="planFilter1 == 11">
                <v-text-field
                  v-model="promotionDataLocal.link"
                  outlined
                  dense
                  label="Link"
                  placeholder="Link"
                  hide-details="auto"
                  type="text"
                ></v-text-field>
              </v-row>
              <v-row class="mb-6" v-if="planFilter1 == 12">
                <v-select
                  v-model="promotionDataLocal.type_id"
                  label="For"
                  placeholder="For"
                  :items="planOptions"
                  item-text="title"
                  item-value="value"
                  outlined
                  dense
                  clearable
                  hide-details
                ></v-select>
              </v-row>

              <v-row class="mb-6" v-if="promotionDataLocal.type_id != 5 && promotionDataLocal.type_id">
                <v-text-field
                  v-model="promotionDataLocal.item_id"
                  :rules="[validators.required, validators.integerValidator]"
                  outlined
                  dense
                  :label="
                    promotionDataLocal.type_id == 1
                      ? 'Chief ID'
                      : promotionDataLocal.type_id == 2
                      ? 'Category ID'
                      : promotionDataLocal.type_id == 3
                      ? 'Item ID'
                      : promotionDataLocal.type_id == 4
                      ? 'Event ID'
                      : ''
                  "
                  :placeholder="
                    promotionDataLocal.type_id == 1
                      ? 'Chief ID'
                      : promotionDataLocal.type_id == 2
                      ? 'Category ID'
                      : promotionDataLocal.type_id == 3
                      ? 'Item ID'
                      : promotionDataLocal.type_id == 4
                      ? 'Event ID'
                      : ''
                  "
                  hide-details="auto"
                ></v-text-field>
              </v-row>

              <v-row class="mb-6">
                <v-text-field
                  v-model="promotionDataLocal.priority"
                  :rules="[validators.required, validators.integerValidator]"
                  @keypress="isNumber($event)"
                  maxLength="1"
                  outlined
                  dense
                  label="priority"
                  placeholder="Priority"
                  hide-details="auto"
                ></v-text-field>

                <v-card-text v-if="message.priority" style="color: #ff3342; white-space: pre-wrap">
                  {{ message.priority[0] }}
                </v-card-text>
              </v-row>

              <v-row class="mb-6" v-if="showImage">
                <v-file-input
                  class="file"
                  v-model="promotionDataLocal.image"
                  :rules="[
                    validators.required,
                    fileAccept(['jpg', 'jpeg', 'png'], promotionDataLocal.image),
                    errorImg(),
                  ]"
                  :prepend-icon="icons.mdiCamera"
                  accept=".jpg, .jpeg, .png"
                  label="Upload Image (.jpg, .jpeg, .png)"
                  hint="Image dimensions: width: 800px, height: 267px,  max-file-size: 512kb"
                  persistent-hint
                  outlined
                  dense
                  :persistent-hint="message.image ? false : true"
                >
                </v-file-input>
              </v-row>
              <v-row class="review-file" v-if="!showImage">
                <p class="uppercase">Upload Image File</p>
                <div class="review-file__btns">
                  <v-btn x-small color="primary" dark @click="openPdf(promotionDataLocal.image)"> Review File </v-btn>
                  <v-btn x-small color="warning" dark @click="editImageFileSizes()"> Edit File </v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>

          <v-col cols="12" class="d-flex justify-center mt-3">
            <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

            <v-btn
              outlined
              color="secondary"
              @click.prevent="$emit('update:is-bio-dialog-open', false)"
              @click="cancel"
            >
              Discard
            </v-btn>
          </v-col>
        </v-form>
      </v-card-text>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { customeRequired, emailValidator, imageValidator, integerValidator, required } from '@core/utils/validation'
import { mdiDelete, mdiPlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { isString } from '@vueuse/shared'
import _ from 'lodash'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    itemData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const promotionDataLocal = ref({})
    const planOptions1 = [
      { title: 'External', value: 11 },
      { title: 'Internal', value: 12 },
      { title: 'None', value: 13 },
    ]
    const planOptions = [
      { title: 'Chefs', value: 1 },
      { title: 'Categories', value: 2 },
      { title: 'Meals', value: 3 },
      { title: 'Events', value: 4 },
    ]
    const showImage = ref(false)
    promotionDataLocal.value = JSON.parse(JSON.stringify(props.itemData))
    const planFilter1 = ref('')
    const valid = ref(false)
    const form = ref(null)
    const priceInPoints = ref(0)
    const message = ref('')
    const isLoading = ref(false)
    const old_img = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const openPdf = file => {
      window.open(file)
    }
    const isNumber = evt => {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }

    const cancel = () => {
      emit('update:is-bio-dialog-open', false)
      emit('refresh')
      message.value = ''
    }

    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 512000) {
          return `MAX SIZE 512KB`
        }
        if (accept.includes(data.name.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }

    const resetForm = () => {
      form.value.reset()
    }

    const editImageFileSizes = () => {
      showImage.value = true
      promotionDataLocal.value.image = null
    }

    const resetItemData = () => {
      promotionDataLocal.value = JSON.parse(JSON.stringify(props.itemData))
      resetForm()
      message.value = ''
    }

    const calculatePoints = e => {
      if (e <= 0) return

      store
        .dispatch('app-promotion/getPriceInPoints', e)
        .then(response => {
          priceInPoints.value = response.data.data.price
          promotionDataLocal.value.points = response.data.data.price
        })
        .catch(error => console.error(error))
    }
    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        isLoading.value = true
        old_img.value = promotionDataLocal.value.image
        if (promotionDataLocal.value.image === props.itemData.image) {
          delete promotionDataLocal.value.image
        }

        await store
          .dispatch('app-promotion/editPromotion', promotionDataLocal.value)
          .then(() => {
            isLoading.value = false

            Swal.fire('Updated!', 'data has been updated successfully.', 'success')
            emit('refresh')
            emit('update:is-bio-dialog-open', false)
          })
          .catch(error => {
            isLoading.value = false
            if (_.isObject(error.data.message)) {
              message.value = error.data.message
            } else {
              message.value = error.data.message
            }
            errorImg()
          })
      } else {
        validate()
      }
    }

    const errorImg = () => {
      if (message.value.image) {
        if (promotionDataLocal.value.image != old_img.value) {
          message.value.image = ''
          return true
        }
        return message.value.image[0] + '\n,Image dimensions: width: 800px, height: 267px,  max-file-size: 512kb'
      } else {
        return true
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        showImage.value = false
        promotionDataLocal.value = JSON.parse(JSON.stringify(props.itemData))

        if ([1, 2, 3, 4].includes(promotionDataLocal.value.type_id)) {
          planFilter1.value = 12
        } else if (promotionDataLocal.value.type_id == 5) {
          planFilter1.value = 11
        } else if (promotionDataLocal.value.type_id == 0) {
          planFilter1.value = 13
        }
      },
    )

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const zeroP = val => {
      return zeroPad(val, 4)
    }
    return {
      zeroP,
      isLoading,
      isNumber,
      message,
      cancel,
      errorImg,
      fileAccept,
      openPdf,
      editImageFileSizes,
      showImage,
      promotionDataLocal,
      form,
      valid,
      resetItemData,
      validate,
      onSubmit,
      calculatePoints,
      priceInPoints,
      planFilter1,
      planOptions1,
      planOptions,
      // validation
      validators: {
        required,
        emailValidator,
        imageValidator,
        integerValidator,
        customeRequired,
      },
      icons: {
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.review-file {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__btns {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.4rem;
  }
}
</style>
