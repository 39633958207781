import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPriceInPoints(ctx, price) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/points-by-price',
          data: {
            price,
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPromotions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/promotions', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPromotion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/promotions/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addPromotion(ctx, data) {
      const bodyFormData = new FormData()
      bodyFormData.append('type_id', data.type_id)
      bodyFormData.append('priority', data.priority)
      bodyFormData.append('image', data.image)
      data.link != null && bodyFormData.append('link', data.link)
      data.item_id != null && bodyFormData.append('item_id', data.item_id)
      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/promotions/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
    editPromotion(ctx, data) {
      const bodyFormData = new FormData()
      bodyFormData.append('type_id', data.type_id)
      data.link != null && bodyFormData.append('link', data.link)
      data.image != null && bodyFormData.append('image', data.image)
      data.item_id != null && bodyFormData.append('item_id', data.item_id)
      bodyFormData.append('priority', data.priority)
      bodyFormData.append('promotion_id', data.id)
      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/promotions/update',
          data: bodyFormData,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    deletePromotion(_ctx, _id) {
      const data = { promotion_id: _id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/promotions/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
